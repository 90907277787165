import React from 'react';
import Button from '@mui/material/Button';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from '../../lib/validations';

function Prompt2FACode({ onSubmit, children }) {
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit} render={({handleSubmit}) => (
      <div className="flex">
        <form
          className="flex"
          name="twoFAForm"
          onSubmit={handleSubmit}
        >
          <h3>{t('Two factor authentication is required')}</h3>
          <div className="flex">
            <div className="layout-align-center-stretch layout-row">
              <TextField
                className='flex-85'
                id="totpcode"
                name="totpcode"
                label={t('Type in code from your authenticator app')}
                autoFocus
                required
                fieldProps={{
                  validate: required
                }}
                autocomplete="new-password"
                margin="normal"
              />
            </div>
          </div>
          <div className="layout-row">
            <div className="flex-20" />
            <Button
              type="submit"
              disableRipple
              variant="contained"
              color="primary"
              className="raisedPrimary flex-60"
              style={{ margin: '6px 0px' }}
            >
              {t('Login')}
            </Button>
            <div className="flex-20" />
          </div>
          {children}
        </form>
      </div>
    )} />
  );
}

export default Prompt2FACode;
